import React from "react";
import useFormatAuthors from "../../hooks/use-format-authors";
import "./PublicationDetails.scss";

const PublicationDetails: React.FC<JQI.Publication> = ({
  year,
  journal,
  issue,
  bookTitle,
  seriesTitle,
  volume,
  datePublished,
  publisher,
  numberOfPages,
  isbn,
  issn,
  doi,
  url,
  relationships,
}) => {
  const isBook = relationships.referenceType.label.indexOf("Book") !== -1;
  const isJournal = relationships.referenceType.label.indexOf("Journal") !== -1;
  return (
    <dl className="publication-details">
      <div className="publication-details__row">
        <dt className="publication-details__label">Authors</dt>
        <dd className="publication-details__value">{useFormatAuthors(relationships.authors)}</dd>
      </div>
      <div className="publication-details__row">
        <dt className="publication-details__label">Publication Type</dt>
        <dd className="publication-details__value">{relationships.referenceType.label}</dd>
      </div>
      <div className="publication-details__row">
        <dt className="publication-details__label">Year of Publication</dt>
        <dd className="publication-details__value">{year}</dd>
      </div>
      {
        (!!bookTitle && isBook) &&
        <div className="publication-details__row">
          <dt className="publication-details__label">Book Title</dt>
          <dd className="publication-details__value">{bookTitle}</dd>
        </div>
      }
      {
        !!seriesTitle &&
        <div className="publication-details__row">
          <dt className="publication-details__label">Series Title</dt>
          <dd className="publication-details__value">{seriesTitle}</dd>
        </div>
      }
      {
        (!!journal && isJournal) &&
        <div className="publication-details__row">
          <dt className="publication-details__label">Journal</dt>
          <dd className="publication-details__value">{journal}</dd>
        </div>
      }
      {
        !!volume &&
        <div className="publication-details__row">
          <dt className="publication-details__label">Volume</dt>
          <dd className="publication-details__value">{volume}</dd>
        </div>
      }
      {
        !!issue &&
        <div className="publication-details__row">
          <dt className="publication-details__label">Issue</dt>
          <dd className="publication-details__value">{issue}</dd>
        </div>
      }
      {
        !!datePublished &&
        <div className="publication-details__row">
          <dt className="publication-details__label">Date Published</dt>
          <dd className="publication-details__value">{datePublished}</dd>
        </div>
      }
      {/* {
        !!publisher &&
        <div className="publication-details__row">
          <dt className="publication-details__label">Publisher</dt>
          <dd className="publication-details__value">{publisher}</dd>
        </div>
      } */}
      {
        !!numberOfPages &&
        <div className="publication-details__row">
          <dt className="publication-details__label">Pagination</dt>
          <dd className="publication-details__value">{numberOfPages}</dd>
        </div>
      }
      {
        !!isbn &&
        <div className="publication-details__row">
          <dt className="publication-details__label">ISBN</dt>
          <dd className="publication-details__value">{isbn}</dd>
        </div>
      }
      {
        !!issn &&
        <div className="publication-details__row">
          <dt className="publication-details__label">ISSN</dt>
          <dd className="publication-details__value">{issn}</dd>
        </div>
      }
      {
        !!doi &&
        <div className="publication-details__row">
          <dt className="publication-details__label">DOI</dt>
          <dd className="publication-details__value">
            <a href={`http://dx.doi.org/${doi}`} className="link link--external">{doi}</a>
          </dd>
        </div>
      }
      {
        !!url &&
        <div className="publication-details__row">
          <dt className="publication-details__label">URL</dt>
          <dd className="publication-details__value">
            <a href={url} className="link link--external">{url}</a>
          </dd>
        </div>
      }
    </dl>
  );
};

export default PublicationDetails;