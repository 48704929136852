const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

module.exports = function usePersonalRoles() {
  const { allTaxonomyTermPersonalRoles, allNodePerson } = useStaticQuery(
    graphql`query AllPersonalRoles {
      allNodePerson(
        filter: {fields: {pagePath: {ne: null}}}
      ) {
        distinct(field: {relationships: {field_person_roles: {name: SELECT}}})
      }
      allTaxonomyTermPersonalRoles(sort: { name: ASC}) {
        edges {
          node {
            id: drupal_internal__tid
            name
          }
        }
      }
    }`
  );

  // A list of the roles used on the current site
  const localRoles = allNodePerson.distinct;
  // Remove roles that are not in localRoles
  const filteredRoles = allTaxonomyTermPersonalRoles.edges.filter((item) => {
    return localRoles.indexOf(item.node.name) !== -1
  });

  return filteredRoles.map((edge) => ({
    ...edge.node,
    id: `${edge.node.id}`,
  }));
};
