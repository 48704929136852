import React from "react";
import { GatsbyImage, getImage, GatsbyImageProps } from "gatsby-plugin-image";
import HomeSection from "../HomeSection";
import ScrollDownIndicator from "../ScrollDownIndicator";
import "./HomeHero.scss";

const HomeHero: React.FC<JQI.HomeHeroProps> = ({
  // title,
  image,
}) => {
  const imageProps = {
    image: getImage(image?.relationships.mediaImage.localFile),
    alt: image?.mediaImage.alt || "",
  } as GatsbyImageProps;
  return (
    <HomeSection className="home-hero">
      {/* <h1 className="home-hero__title">{title}</h1> */}
      {
        !!imageProps.image &&
        <GatsbyImage className="home-hero__image" {...imageProps} />
      }
      <ScrollDownIndicator className="home-hero__scroll-down-indicator" />
    </HomeSection>
  );
};

export default HomeHero;